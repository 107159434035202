/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CategoryProductList {
    @include desktop {
        inset-block-start: -56px;
        grid-column: 2;
        padding-block-end: 2.4rem;
    }

    &-Page {
        &_layout {
            &_grid {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-auto-rows: max-content;
                grid-column-gap: 1.2rem;

                @include tablet {
                    grid-template-columns: repeat(2, 1fr);
                }

                @include mobile {
                    grid-column-gap: 1.4rem;
                    // remove margin from top to other pages
                    grid-template-columns: 1fr 1fr;
                    border: 0;
                }

                @include desktop {
                    grid-column: 2;
                    margin-block-start: 10px;

                    .ProductCard {
                        .ProductCard-Figure {
                            .Image img {
                                transition: transform 0.35s ease-in-out;
                            }
                        }

                        &:hover .ProductCard-Figure .Image img {
                            transform: scale(1.2);
                            transform-origin: 50% 50%;
                            transition: transform 0.5s, visibility 0.5s ease-in;
                        }
                    }
                }

                // remove margin from top to other pages

                ~ .CategoryProductList-Page {
                    @include desktop {
                        margin-block-start: 0;
                    }
                }
            }

            &_list {
                display: block;
                max-width: 550px;

                .ProductCard-Link {
                    flex-direction: row;
                    max-width: 100%;
                    width: fit-content;

                    .Image {
                        width: 200px;
                    }
                }
            }
        }

        // remove margin from top to other pages

        ~ .CategoryProductList-Page {
            @include desktop {
                margin-block-start: 0;
            }
        }
    }

    &-ProductsMissing {
        padding: 1.2rem;
        text-align: center;

        @include mobile {
            padding: 1.4rem;
        }
    }

    &-More {
        min-height: 3.6rem;

        @include mobile {
            min-height: 4.2rem;
        }
    }

    &-LoadButton {
        background-color: var(--category-product-list-load-button-background);
        cursor: pointer;
        font-size: 13pt;
        margin-block-end: 1.2rem;
        padding: 1em 0;
        text-align: center;

        @include mobile {
            margin-block-end: 1.4rem;
        }
    }
}
