/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable declaration-no-important */

.Slider-Arrow_isNext {
    right: 0;
}

.Slider-Arrow_isPrev {
    left: 0;
}

.ProductGallery {
    // .react-transform-component {
    //     border: 1px solid #e2e2e2;
    // }

    &-Additional {
        @include mobile {
            display: none;
        }
    }

    .mainSlider {
        .slick-slide {
            iframe {
                width: 100%;
                height: 269px;
                display: block;
            }
        }
    }

    .thumbnailSlider {
        .slick-slider {
            padding: 0;
        }

        .slick-slide {
            padding: 0 3px;
            margin-top: 1px;
            
            .border {
                border-radius: 2px;
                height: 50px;
                background: #eee;

                &.active-thumb {
                    transform: none;
                    border-color: var(--text-red-color);
                    outline: 1px solid var(--text-red-color) !important;
                }

                &:empty {
                    background-image: url(../../images/rotate.png);
                    background-color: #E0E2E2;
                    background-repeat: no-repeat;
                    background-size: 18px;
                    background-position: top 8px center;
        
                    &::before {
                        content: "360'";
                        position: absolute;
                        font-family: "regular-bold", sans-serif;
                        bottom: 4px;
                        left: 0;
                        right: 0;
                        text-align: center;
                        font-size: 12px;
                    }
                }
            }
        }

        .slick-next, .slick-prev {
            background-color: rgba(0,0,0,.5);
            z-index: 9;
            display: flex !important;
            align-items: center;
            justify-content: center;
            width: 26px;
            height: 26px;
            border-radius: 4px;

            &.slick-disabled {
                display: none !important;
            }
        }

        .slick-prev {
            left: 4px;
            z-index: 9;
        }

        .slick-next {
            right: 4px;
            z-index: 9;
        }
        
        .slick-prev::before, 
        .slick-next::before {
            font-family: sans-serif;
            content: "‹";
            opacity: 1;
            font-size: 25px;
            line-height: unset;
        }

        .slick-next::before {
            content: "›";
        }
    }

    .slick-slider {
        touch-action: pan-x pan-y pinch-zoom !important;
        padding: 15px 0 8px; 

        .border {
            border: 1px solid #ddd;
        }

        .slick-dots {
            bottom: 25px;

            li {
                margin: 0;

                button {
                    &::before {
                        font-size: 12px;
                        color: #d6d6d6;
                        opacity: 1;
                    }
                }

                &.slick-active {
                    button {
                        &::before {
                            color: #2db0bc;
                        }
                    }
                }
            }
        }
    }

    .ProductGallery {
        &-ImageLine {
            display: flex;
            flex-direction: row;
            width: 100%;
            margin: 3px 0 7px 0;
            color: var(--text-dark-gray-color);

            div {
                font-family: regular-book, sans-serif;
                font-weight: 400;
                //background-color: rgba(0,0,0,0.8);
                font-size: 14px;
            }

            @include mobile {
                margin: -15px 0 15px 0;
            }
        }
    }

    iframe.co.a3.ai.aj.f {
        height: 470px;

        @media (min-width: 1600px) {
            height: 516px;
        }

        @include mobile {
            height: 345px;
        }

        @media (max-width: 1024px) {
            height: 370px;
        }

        @media (max-width: 500px) {
            height: 260px;
        }
    }

    &-Slider_isImageZoomPopupActive {
        iframe.co.a3.ai.aj.f {
            height: calc(100vh - 106px);
        }
    }

    &ThumbnailImage {
        &:empty {
            background-image: url(../../images/rotate.png);
            background-color: #E0E2E2;
            background-repeat: no-repeat;
            background-position: top 16px center;
            display: flex;
            height: 100%;

            &::before {
                content: "360'";
                position: absolute;
                font-family: "regular-bold", sans-serif;
                inset-block-end: 17px;
                inset-inline: 0;
                text-align: center;
                font-size: 15px;
            }
        }
    }
}

.Popup.ImageZoomPopup {
    background: rgba(0,0,0,.8);
    padding: 0;
    z-index: 9999999999 !important;

    button.Popup-CloseBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 999px;
        right: 20px;
        inset-block-start: 15px;
        width: 50px;
        height: 50px;

        @media (max-width: 1200px) {
            right: 15px;
        }

        .CloseIcon {
            fill: #333;
            width: 35px;
            height: 35px;

            &:hover {
                fill: var(--primary-base-color);
            }
        }
    }

    .ImageZoomPopup .Slider-Arrow {
        background-color: #fff;
        box-shadow: 1px 1px 3px rgba(0,0,0,.6);
        border-radius: 999px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;

        &_isNext {
            right: 20px;
        }

        &_isPrev {
            left: 20px;
        }

        svg {
            width: 30px;
            fill: #333;

            &:hover {
                fill: var(--primary-base-color);
            }
        }
    }

    .Popup-Content {
        background: transparent;
        padding: 0;

        .ProductGallery {
            margin: 0;
            padding: 0;
            display: block;

            .ProductGallery-Additional {
                order: 1;
                padding: 0;
                height: 106px;
                background: #000;
                position: absolute;
                inset-block-end: 0;
                inset-inline: 0;

                @media (max-width: 1200px) {
                    height: 72px;

                    .CarouselScroll {
                        &-Content {
                            flex-wrap: wrap;

                            .CarouselScrollItem {
                                width: 92px;
                                height: 74px;
                            }
                        }
                    }
                }

                .Videos {
                    display: none;
                }

                .CarouselScroll {
                    width: auto;
                    margin: 0;
                    padding-block-start: 10px !important;

                    &-ContentWrapper {
                        width: 100%;
                        justify-content: center;
                    }

                    &Item {
                        width: 138px;
                        height: 106px;
                        padding: 2px 1px;
                        border: none;
                        margin: 0;

                        .ProductGalleryThumbnailImage {
                            padding: 0;
                            height: auto;

                            div.Image {
                                border: 3px solid #fff;

                                &:hover {
                                    border: 3px solid #ccc;
                                }
                            }

                            img.Image-Image {
                                position: static;
                            }
                        }
                    }
                }
            }

            .ProductGallery-SliderWrapper {
                height: calc(100vh - 115px) !important;

                .ProductGallery-Slider {
                    img.Image-Image {
                        width: auto !important;
                        max-height: 95%;
                        max-width: 95%;
                        margin: 0 auto;
                        display: block;
                        position: static;
                        transform: unset !important;
                    }

                    &Image {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: calc(100vh - 106px);

                        @media (max-width: 1200px) {
                            height: calc(100vh - 74px);
                        }
                    }
                }
            }
        }
    }
}

.Videos {
    //height: 82px;
    //width: 82px;
    //min-width: 82px;
    background-color: #E0E2E2;
    font-family: "regular-bold", sans-serif;
    font-size: 15px;
    text-transform: uppercase;
    text-align: center;
    color: var(--text-dark-gray-color) !important;

    .ProductGalleryContent {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    svg {
        color: var(--text-dark-gray-color) !important;
        font-size: 32px;
        display: block;
        margin: 5px auto;
    }

    &.mobileView {
        position: absolute;
        z-index: 997;
        inset-block-start: 15px;
        right: 0;
        width: 50px;
        min-width: unset;
        height: 50px;
        font-size: 11px;

        svg {
            font-size: 22px;
            margin: 7px auto 5px;
        }
    }
}


.ProductGallery-SliderPromo {
    position: absolute;
    overflow: hidden;
    z-index: 1;

    .promo-content {
        position: relative;
        z-index: 2;
        line-height: normal;
    }
}

.active-thumb {
    border: 2px solid #007bff; /* or any color you prefer */
    transform: scale(1.05);
    transition: all 0.3s ease;
}
