/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable rule-empty-line-before */
/* stylelint-disable csstools/use-logical */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-no-important */
/* stylelint-disable property-no-unknown */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --product-actions-option-border-color: #979797;
    --product-actions-selected-option-border-color: #f26323;
    --product-brand-heading-color: #adadad;

    @include desktop {
        --product-actions-option-border-color: #adadad;
    }
}

.ProductPage {
    @include desktop {
        .ProductGallery {
            display: flex;
            flex-direction: column;

            &-Additional {
                display: flex;
                order: 1;
                margin-block: 5px 20px;
                border-top: none;

                .Image-Image {
                    position: static;
                    object-fit: cover;
                }
            }
        }
    }

    .SmartWidget {
        border: 1px solid #ccc;
        margin-block-end: 20px;
        border-radius: 5px;

        &Des {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: flex-start;
            font-family: regular-book, sans-serif;
            font-size: 17px;
            line-height: 1.1;

            .image {
                float: inline-start;
                width: 100%;
                max-width: 140px;
                position: relative;
                overflow: hidden;

                @include mobile {
                    max-width: 120px;
                }

                img {
                    border-radius: 5px;
                }

                .promo_label {
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    background: var(--text-red-color);
                    color: #fff;
                    line-height: 1;
                    font-weight: 400;
                    border: 1px solid var(--text-red-color);
                    text-align: center;
                    width: 138px;
                    transform: rotateZ(-45deg);
                    transform-origin: center center;
                    font-family: regular-bold, sans-serif;
                    text-transform: uppercase;
                    user-select: none;
                    font-size: 13px;
                    padding: 2px 0;
                    margin: 13px -45px;
                    z-index: 1;

                    &::after {
                        content: "";
                        inset-block-start: -1px;
                        transform: translateX(100%);
                        width: 220px;
                        height: 20px;
                        position: absolute;
                        z-index: 1;
                        animation: slide 4s infinite 3s;
                        background: linear-gradient(to right,
                        rgba(255, 255, 255, 0) 0%,
                        rgba(255, 255, 255, 0.5) 50%,
                        rgba(128, 186, 232, 0) 99%,
                        rgba(125, 185, 232, 0) 100%);
                    }
                }
            }

            .title {
                text-transform: uppercase;
            }

            .content {
                padding: 15px 20px;

                a {
                    color: var(--text-dark-gray-color);
                    text-decoration: underline;

                    &:hover {
                        color: var(--text-red-color);
                    }
                }

                b, strong {
                    margin-right: 6px;
                }
            }

            .text_after {
                font-family: regular-book, sans-serif;
                font-weight: normal;
                font-size: 17px;
                color: #777;
            }

            .btn.btn-primary {
                color: #fff !important;
                text-decoration: none;
            }
        }

        &-actions {
            margin-block-start: 15px;
        }

        .listPrice {
            display: block;
            font-family: regular-bold, sans-serif;
            font-size: 18px;
        }

        .currentPrice {
            display: block;
            font-family: regular-bold, sans-serif;
            font-size: 18px;
            text-decoration: line-through;
            color: #777;
            margin-block-end: 2px;
        }

        .title {
            font-family: regular-bold, sans-serif;
            margin-block-end: 2px;
            font-size: 20px;

            &.title-2 {
                margin-block-start: 10px;
            }
        }

        &ImgPrice {
            display: flex;
            align-items: center;
            padding: 0 10px 15px 20px;
        }

        &Img {
            width: 110px;
        }

        &Button {
            margin-left: auto;
            text-align: center;

            button {
                display: block;
                padding: 1px 25px;
                margin-block-end: 7px;

                &.Adding\.\.\. {
                    opacity: .5;
                }
            }

            a {
                color: var(--text-dark-gray-color);
                text-decoration: underline;

                &:hover {
                    color: var(--text-red-color);
                    text-decoration: none;
                }
            }
        }

        .price-box-text {
            display: block;
            font-size: 10px;
            line-height: 1;
            color: var(--text-light-black-color);
            font-weight: normal;
            font-family: regular-book, sans-serif;
        }
    }

    .ProductGallery-Notice {
        display: none;
    }

    .ProductActions {
        .brand-sku {
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            flex-direction: row;
            justify-content: flex-start;
        }

        &-Attribute_type_product_brand,
        &-Attribute_type_product_code,
        &-Attribute_type_product_sku {
            font-family: regular-book, sans-serif;
            font-size: 16px;
            font-weight: normal;
            color: #888888;
            margin-block-end: 8px;
            text-transform: uppercase;

            @include mobile {
                font-size: 14px;
            }
        }

        .separator {
            margin: 0 8px;
            color: #CCC;
        }

        .pay-later-wrap.active {
            min-height: 68px;
        }

        #buynowpaylateroptions {
            // background-color: #f9f9f9;
            // border: 1px #e2e2e2 solid;
            // border-radius: 10px;
            // padding: 8px 12px;
            margin: 0 0 15px 0;
            min-height: 44px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            #bread-payments {
                font-family: regular-book, sans-serif;
                font-size: 16px;
                line-height: normal;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 10px;

                @include mobile {
                    flex-direction: row;
                    justify-content: center;
                    font-size: 14px;
                    img {
                        width: 60px !important;
                    }

                    #bread-payments-text {
                        justify-content: center;
                    }
                }

                &-text {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    flex: 1;

                    &-month {
                        margin-inline-end: 5px;
                    }
                }

                &-logo {
                    img {
                        margin-block-start: 2px;
                    }
                }

                #bread-payments-text-apr {
                    display: flex;
                    flex-direction: column;
                    gap: 1px;
                    color: var(--text-dark-gray-color);

                    &:hover {
                        color: var(--text-dark-gray-color);
                        text-decoration: underline;
                    }

                    svg {
                        font-size: 14px;
                        color: #666;
                        margin-left: 5px;
                    }
                }

                #bread_div {
                    margin: 0 !important;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: center;

                    .bread-modal-button {
                        text-decoration: underline;
                        cursor: pointer;

                        &:hover {
                            color: #58BEC8;
                        }
                    }
                    
                    #bread-finance-btn, #bread-prequal-btn {
                        // opacity: 1;
                        // visibility: unset !important;
                        // padding: 0;
                        // display: none;
                        // &:empty {
                        //     display: none;
                        // }
                        // &.is-visible {
                        //     display: block;
                        // }
                        // iframe {
                        //     position: absolute !important;
                        //     top: 0;
                        //     opacity: 0;
                        // }

                        .bread-as-low-as-tooltip-external {
                            display: none;
                        }

                        > div {
                            cursor: pointer;

                            a {
                                font-size: 0;
                                color: inherit;

                                &::before {
                                    content: "Learn More";
                                    text-decoration: underline;
                                    position: relative;
                                    font-size: 16px;
                                }

                                &:hover {
                                    color: #58BEC8;
                                }
                            }   
                        }

                        @include mobile {
                            margin-block-end: 0;
                        }
                    }
                }

                strong {
                    font-family: regular-bold, sans-serif;
                    font-weight: 300;
                }
            }
        }

        &-ConfigurablePriceBadge {
            font-family: "Regular-Book", sans-serif;
            color: #444;
            font-size: 16px;
            margin-block-end: 0;
        }

        .ProductName {
            margin-top: 6px;
            order: 0;

            &-Line1 {
                display: block;
                width: 100%;
                font-size: 20px;
                color: #777777;
                margin-block-end: 2px;

                @include mobile {
                    font-size: 18px;
                }
            }

            &-Line2 {
                display: block;
                width: 100%;

                @include mobile {
                    font-size: 22px;
                }
            }
        }

        --swatch-type-size: 40px;

        @include desktop {
            --swatch-type-size: 35px;

            display: flex;
            flex-flow: row wrap;
            height: max-content;
            flex-direction: column !important;
        }

        @include mobile {
            margin-top: 8px;
            display: grid;
            align-items: center;
        }

        &-Colors {
            grid-column-gap: 1.5rem;
            grid-template-columns: repeat(auto-fit, minmax(min-content, 0));
            flex-basis: 100%;
            margin-block-end: 2rem;

            @include desktop {
                display: flex;
                flex-wrap: wrap;
                order: 11;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 0.5rem calc(1rem - 5px);
            }
        }

        &-Reviews {
            order: -1;
            margin-block-end: 1rem;
            display: flex;
            flex-direction: row;
            align-content: center;
            justify-content: flex-start;
            align-items: flex-start;

            svg.StarIcon {
                fill: var(--star-icon-color);
            }
        }

        &-ReviewLabel {
            display: inline-block;
            font-weight: 600;
            margin-left: 0.5rem;
            margin-block-end: 0;

            span {
                font-weight: normal;

                &::before {
                    content: " / ";
                }
            }
        }

        &-Color {
            @include desktop {
                margin: 6px;
            }
        }

        &-Price {
            @include mobile {
                order: -2;
            }
        }

        &-Sku::after {
            content: "•";
            margin: 0 5px;
        }

        &-Title {
            font-size: 2rem;
            font-weight: bold;
            line-height: 3rem;
        }

        &-AddToCart.Button {
            min-width: unset;
            border-radius: 999px;

            &:hover, &:focus {
                border-radius: 999px;
            }
        }

        &-AddToCartWrapper {
            margin-block-end: 0 !important;
            display: flex;
            flex-direction: column;

            .cart-wishlist-wrap {
                width: 100%;

                .actions {
                    display: flex;
                    align-items: flex-start;
                    margin-block-start: 5px;
                    flex: 1;

                    @include mobile {
                        align-items: center;
                    }

                    .out_of_stock {
                        font-family: regular-bold, sans-serif;
                        font-weight: bold;
                        font-size: 20px;
                        margin-block-end: 10px;
                        text-transform: uppercase;
                    }

                    &-addToCart {
                        flex: 1;
                        max-width: 300px;

                        .Button {
                            width: 100%;

                            &.addToCartDis {
                                opacity: .25;
                                pointer-events: none;
                            }
                        }

                        .ProductWishlistButton {
                            svg {
                                font-size: 13px;
                                margin-right: 5px;
                            }
                        }
                    }

                    &-additional {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        flex: 1;
                        text-align: center;
                        justify-content: center;
                        margin-block-start: 12px;

                        @include mobile {
                            display: none;
                        }
                    }

                    &.no_stock {
                        flex-direction: row;

                        .actions-additional {
                            margin-block-start: 0;
                            justify-content: flex-end;
                        }
                    }
                }
            }

            @include desktop {
                width: 100%;
                box-sizing: border-box;
                padding: 0 0 15px 0;
                align-items: center;
                text-align: center;
                justify-content: space-between;
                flex-wrap: wrap;
                flex-direction: unset;
                background-color: #fff;
                position: -webkit-sticky;
                position: sticky;
                inset-block-end: -2px;
                border-right: 1px #fff solid;
            }

            @media (max-width: 992px) {
                justify-content: center;
            }

            .AddToCart {
                flex: 1;
                padding: 4px 20px;
                border-radius: 999px;

                &:hover {
                    padding: 4px 20px;
                    border-radius: 999px;
                }

                @include desktop {
                    font-size: 16px;
                }
            }
        }

        &-TierPrices {
            display: none;
        }

        .ProductActions-AttributesWrapper {
            .ProductActions-Attributes {
                border: none;
                margin-block-end: 0;
                padding: 0;

                article.ExpandableContent {
                    border: none;
                }

                button.ExpandableContent-Button {
                    display: none;
                }

                .ExpandableContent-Content_isContentExpanded {
                    padding: 0 5px;

                    .ProductConfigurableAttributeDropdown {
                        margin: 0 0 6px;

                        select {
                            height: 35px;
                            padding: 4px 6px;
                            border: 2px solid var(--button-border);
                            font-family: regular-bold, sans-serif;
                            font-size: 16px;
                            color: var(--text-dark-gray-color);
                            padding-inline-end: 33px;
                        }
                    }
                }
            }
        }

        .service-warranty-cont {
            display: flex;
            justify-content: space-evenly;
            padding: 15px 0;
            //background: #f5f5f5;
            //border: 1px solid #e2e2e2;
            border-top: 1px solid #e2e2e2;
            border-bottom: 1px solid #e2e2e2;
            width: 100%;
            margin-block-end: 15px;

            @include mobile {
                display: none;
            }

            & > div {
                display: flex;

                &:last-child {
                    .divider {
                        &:last-child {
                            display: none;
                        }
                    }
                }

                & > div {
                    padding: 0 12px;
                    text-align: center;

                    &:last-child {
                        border-right: none;
                    }

                    h4.h4 {
                        font-size: 13px;
                        font-weight: 400;
                        text-transform: uppercase;
                        line-height: 13px;
                        color: var(--text-light-black-color);
                        font-family: regular-bold, Helvetica, Arial, sans-serif;
                        margin-block-end: 5px;
                    }

                    h3.h3 {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 1;
                        letter-spacing: 1px;
                        font-family: regular-bold, sans-serif;
                        margin: 0;
                        text-transform: uppercase;
                    }

                    &.divider {
                        padding: 0;
                        border-right: #e2e2e2 solid 1px;
                    }

                    &.fr-trust h3.h3 {
                        color: var(--text-blue-color);
                    }

                    &.fr-guarantee h3.h3 {
                        color: var(--text-orange-color);
                    }

                    &.fr-life-time h3.h3 {
                        color: var(--text-red-color);
                    }
                }
            }
        }

        &-AdditionalButtons {
            display: flex;
            margin: 20px 0;

            @include desktop {
                display: none;
                width: auto;
                order: 0;
                margin: 0;

                #bread-btn-cntnr {
                    display: none;
                }
            }

            .Button {
                border-radius: 999px;
                padding: 4px 20px;
                background-color: var(--btn-color);
                border-color: var(--button-border);
                color: var(--btn-text-color);
                margin-right: 15px;

                svg.fa-heart {
                    font-size: 13px;
                    margin-right: 4px;
                }

                &:hover, &:focus {
                    padding: 4px 20px;
                }

                @include desktop {
                    font-family: regular-book, sans-serif;
                    font-weight: normal !important;
                    background: transparent !important;
                    color: #333 !important;
                    border: 0;
                    border-bottom: 1px dotted transparent;
                    opacity: 1;
                    margin: 0 10px;
                    padding: 0 !important;
                    text-transform: unset;
                    font-size: 16px;
                    line-height: initial;
                    border-radius: 0 !important;

                    &:hover {
                        color: var(--text-red-color) !important;
                        border-bottom: 1px solid var(--text-red-color);
                    }
                }
            }

            .Button[disabled] {
                opacity: 1;
            }

            &:first-child {
                margin-block-start: 0;
            }

            >* {
                @include mobile {
                    //margin: 0;
                    //text-align: center;
                }
            }
        }

        .cart-wishlist-wrap {
            .ProductActions-AdditionalButtons {
                display: flex;
            }

            @include mobile {
                position: fixed;
                inset-block-end: var(--navigation-tabs-height);
                inset-inline: 0;
                z-index: 11;
                background: var(--cart-wishlist-bg);
                padding: 6px 10px 10px 10px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background-color: var(--header-background);
                border-top: 1px var(--primary-divider-color) solid;

                .ProductActions-AdditionalButtons {
                    display: none;
                }

                #bread-btn-cntnr {
                    display: none;
                }
            }

            .notify_me {
                display: flex;

                input[type="text"],
                input[type="email"] {
                    border: 2px solid rgba(0, 0, 0, 0.2);
                    border-right: 0;
                    padding: 10px 15px;
                    height: 44px;
                    width: 100%;
                    border-top-left-radius: 999px;
                    border-bottom-left-radius: 999px;
                }

                .Field {
                    margin-block-start: 0;
                    width: 100%;
                }

                button {
                    font-size: 18px !important;
                    margin-left: 0;
                    border-top-right-radius: 999px;
                    border-top-left-radius: 0;
                    border-bottom-right-radius: 999px;
                    border-bottom-left-radius: 0;
                    height: 44px;
                    padding: 0 15px 0 10px;
                    width: auto !important;

                    &:hover, &:focus {
                        border-top-right-radius: 999px !important;
                        border-top-left-radius: 0 !important;
                        border-bottom-right-radius: 999px !important;
                        border-bottom-left-radius: 0 !important;
                    }
                }
            }

            &.no_stock {
                position: static;
                flex-wrap: wrap;
                margin-block-end: 12px;
                justify-content: unset;

                .notify_me {
                    flex: 1;
                    align-items: flex-start;
                    width: calc(100% - 136px);
                }

                .btn.out_of_stock {
                    font-size: 16px;
                    pointer-events: none;
                    margin-block-end: 10px;
                    border-radius: 999px;
                    padding: 4px 20px;
                }

                .ProductNotifyEmail {
                    display: flex;
                    flex-direction: column;

                    &-Capture {
                        display: flex;
                        flex-direction: row;
                    }

                    &-Label {
                        width: 100%;
                        margin-block-end: 10px;
                        text-align: left;
                        font-family: regular-bold, sans-serif;
                        font-size: 17px;
                        color: #333;
                        justify-content: unset;
                    }

                    &-Message {
                        margin-block-start: 10px;
                        text-align: left;
                        font-family: regular-book, sans-serif;
                        color: #333;
                    }
                }

                @include desktop {
                    order: 1;
                    border: none;
                    padding: 0;
                    margin-block-end: 0;
                }

                @media (max-width: 992px) {
                    margin-block-start: 10px;
                }
            }
        }

        .cart-wishlist-wrap.no_stock {
            background: none;
            padding: 0;
            border: 0;
        }

        .no_stock + .ProductActions-AdditionalButtons {
            @include desktop {
                margin-left: auto;
            }

            @media (max-width: 992px) {
                width: 100%;
            }
        }

        .EmailToFriend {
            margin-block-end: 30px;

            @include mobile {
                margin-block-end: 15px;
            }

            .ContentWrapper {
                padding: 0;

                .Button {
                    background: var(--transform-bg);
                    border: none;
                    padding: 0;
                    font-family: regular-book, sans-serif;
                    font-weight: normal;
                    color: var(--text-dark-gray-color);
                    font-size: 14px;
                    text-transform: none;
                    height: auto;
                }
            }
        }

        &-Attributes {
            @include mobile {
                --product-configurable-attributes-content-padding: 0.5rem;
                // --product-configurable-attributes-direction: center;
                --option-margin: 0 0.5rem 1rem;

                border-top: 1px solid var(--expandable-content-divider-color);
            }

            @include desktop {
                --product-configurable-attributes-margin: 0 2rem 0 0;
                --option-margin: 0 1rem 1rem 0;

                order: 10;
                display: flex;
                flex-wrap: wrap;
                flex-basis: 100%;
                padding: 1.5rem calc(1rem - 5px);
            }
        }

        &-Section {
            display: flex;
            flex-wrap: wrap;
            flex-basis: 100%;

            &.ProductActions-Section_type_short {
                font-family: regular-book, sans-serif;
                font-weight: normal;
                font-size: 18px;
                color: var(--text-light-black-color);
                margin: 2px 0 20px;
                line-height: 1.1;

                p {
                    line-height: 1.1;
                    color: inherit;

                    &:last-child {
                        margin-block-end: 0;
                    }
                }

                * {
                    font-size: inherit !important;
                    text-align: inherit !important;
                }
            }

            &_type_bundle_items {
                display: none !important;
            }

            @include desktop {
                order: 0;
                font-size: 16px;
            }

            &_type {
                &_related {
                    border-top: 0;
                    padding: 1.5rem 0;
                }

                &_short {
                    @include desktop {
                        order: 0;
                        flex-basis: 100%;
                    }
                }

                &_sku {
                    order: 2;
                    flex-basis: 100%;
                    font-size: 1rem;
                    font-weight: bold;
                    color: var(--product-actions-option-border-color);
                    padding-block: 0;
                    margin-block-end: 1rem;

                    @include mobile {
                        display: none;
                    }
                }

                &_name {
                    order: 1;
                    flex-basis: 100%;
                    flex-direction: column;
                    text-align: start;
                    padding-block-end: 0;
                    display: none;
                }

                &_grouped {
                    @include desktop {
                        margin-block-end: 1.5rem;
                    }
                }
            }
        }

        &-SectionHeading {
            width: 100%;
            text-transform: uppercase;
            margin: 0 5px 1rem;
            font-weight: 600;
            line-height: 1;

            &_type {
                &_related {
                    margin-left: 1rem;
                }

                &_brand {
                    @include desktop {
                        display: none;
                    }
                }
            }
        }

        &-SectionContent {
            margin: 0 5px;

            &_type {
                &_short {
                    &-name {
                        @include mobile {
                            display: none;
                        }
                    }
                }
            }
        }

        &-PlaceholderOption {
            margin: 5px;
            padding: 0 1rem;
        }

        &-TextOption {
            margin: 6px;
            padding: 0 12px;
            border: 1px solid var(--product-actions-option-border-color);

            &_isSelected {
                --product-actions-option-border-color: var(
                    --product-actions-selected-option-border-color
                );
            }
        }

        label {
            display: inline-block;
            font-family: regular-book, sans-serif;
            cursor: help;
            border-bottom: 1px dotted #999;
        }

        &-Qty.Field,
        .GroupedProductsItem-Quantity {
            margin-block-start: 0;
            display: flex;
            margin: 0 10px 0 0;
            border-radius: 999px;
            border: 2px solid #e2e2e2;
            padding: 2px 10px;
            background-color: rgba(255,255,255,0.7);

            button {
                border: 0 !important;
            }

            .MinusIcon_isPrimary,
            .AddIcon_isPrimary {
                fill: var(--color-black);
            }

            input {
                padding: 2px;
                height: 35px;
                width: 40px;
                min-width: unset;
                font-weight: normal;
                font-size: 18px;
                color: #333;
                background: transparent;
                margin: 0;
                border: none;

                &[type="number"] ~ button {
                    width: 30px;
                    height: 30px;
                    border-radius: 0;
                    text-align: center;

                    @include desktop {
                        width: 26px;
                        height: 26px;
                    }
                }

                @include mobile {
                    text-align: center;
                }
            }
        }

        .GroupedProductsItem {
            border: 1px solid #e2e2e2;
            padding: 10px;
            margin-block-end: 10px;

            &-Quantity {
                order: unset;
                margin: 0 0 0 auto;
            }

            &-Title {
                font-family: "regular-bold", sans-serif;
                font-size: 15px;

                p {
                    text-transform: uppercase;
                    color: #333;
                }

                .ProductPrice {
                    font-size: 19px;
                }
            }
        }

        &-MetaDescription {
            display: none !important;
        }
    }

    .product-actions-bottom {
        display: flex;
        flex-direction: column;
        align-items: start;
        flex-wrap: wrap;
        gap: 8px;
        margin-block-end: 20px;

        a {
            text-decoration: underline;
            color: var(--text-dark-gray-color);

            &:hover {
                color: #000;
            }
        }

        .can-ship,
        .yotpo-loyalty-earn-points,
        .limited-stock,
        .SalesCountdown-Block {
            font-family: regular-book, sans-serif;
            display: flex;
            flex-direction: row;
            align-items: start;
            gap: 8px;
            height: 17px;
            font-size: 16px;

            // First element set to flex: 1
            svg {
                width: 16px;
                height: 16px;
            }
        }

        .can-ship-now {
            color: #91a513;
        }

        .cant-ship-now {
            visibility: hidden;
        }
    }

    .CarouselScrollItem + .CarouselScrollItem {
        margin-top: 0;
    }

    .ProductGallery-Additional {
        width: 100%;
        height: auto;
    }

    .ProductGalleryThumbnailImage {
        padding: 0;
        height: auto;
        border-radius: 1px;
        display: block;
        line-height: 0;
        overflow: hidden;
    }

    .CarouselScroll {
        &-ContentWrapper {
            padding: 1px;
        }

        &-Content {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(15%, 1fr));
            grid-gap: 5px;
            width: 100%;
            transform: unset;
        }

        &Item {
            height: auto;
            border-radius: 0px;
            box-sizing: border-box;
            margin-left: 0;

            @include desktop {
                filter: unset;
                transition: unset;
                border: 1px solid #e2e2e2 !important;
                outline: 1px solid transparent;
                border-radius: 2px;
                position: relative;
                flex-grow: 1;
                flex-shrink: 0;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                width: 100%;

                .ProductGalleryThumbnailImage {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                }

                &::before {
                    content: '';
                    display: block;
                    padding-block-start: 85%;
                }

                &:hover img {
                    transform: scale(1.25);
                }

                &_isActive {
                    border: 1px solid var(--text-red-color) !important;
                    outline: 1px solid var(--text-red-color) !important;
                    transition: transform 0.5s ease-in-out;
                    pointer-events: none;
                }

                img {
                    height: 100% !important;
                    width: 100% !important;
                    transform: scale(1.05);
                    border: 0px solid #fff;
                    transform-origin: 50% 50%;
                    transition: transform 0.25s, visibility 0.25s ease-in;
                    object-fit: cover !important;
                    overflow: hidden;
                }
            }
        }
    }

    button.CarouselScrollArrow {
        display: none;
    }

    .ProductGallery-Additional .CarouselScroll {
        width: 100%;
        transform: unset !important;
        --translateY: unset !important;

        margin-block-start: 0;
    }

    & ~ .Breadcrumbs {
        // li.Breadcrumb {
        //     display: none;
        //     &:first-child,
        //     &:last-child {
        //         display: inline-block;
        //     }
        // }
    }

    .ProductActions-Attributes {
        &>div {
            width: 100%;
        }
    }

    .ProductAttributeValue {
        &-Color {
            border-radius: 0;
            margin: 0;
            width: 32px;
            height: 32px;

            &::before {
                left: 0;
                width: 32px;
                height: 32px;
                top: 0px;
                border: 2px solid var(--primary-base-color);
                border-radius: 0;
                box-shadow: none;
                box-sizing: border-box;
            }

            &::after {
                left: 9px;
            }
        }

        &-String,
        &-Text {
            border: 2px #dedede solid;
            margin-inline-end: 5px;
            padding: 0 5px;
            border-radius: 0;
            min-width: 32px;
            font-size: 14px;
            height: 32px;
            line-height: 30px;
            color: #666;

            &:hover,
            &_isSelected {
                border-color: var(--text-red-color);
                color: var(--text-red-color);
            }
        }
    }

    div#bread-btn-cntnr button#payjustnowinfo {
        margin: 0 10px 0 15px;
        background-color: #3c3d3c;
        border-color: #999;
        border-radius: 999px;
        font-size: 16px;
        padding: 4px 20px;

        @include mobile {
            margin: 0;
        }

        &:hover {
            opacity: 0.8;
        }
    }

    #expertvoice-recommendations {
        width: 100%;
    }
    .yotpo-pictures-widget {
        width: 100%;
        .yotpo-slider-wrapper {
            max-width: 100% !important;
        }
    }

    .faqSection {
        &Wrap {
            margin: 50px 0;
            width: 100%;
            * {
                position: static;
            }
            .ProductQA-Button {
                position: absolute;
                top: 0;
                right: 0;
            }
            h3.ProductQA-Title {
                display: none;
            }
            h2 {
                text-transform: uppercase;
                font-family: regular-bold, sans-serif;
                font-size: 34px;
                line-height: 1.1;
                margin: 0 0 50px;

                @include mobile {
                    font-size: 28px;
                    margin: 0 0 30px;
                    padding-right: 125px;
                }
            }
            .ContentWrapper {
                padding: 0;
            }
            .qa-des {
                font-size: 16px;
            }
            .singleQA {
                border-bottom: 1px solid #e2e2e2;
                padding-block-end: 15px;
                margin-block: 15px;
                cursor: pointer;
                .question-content {
                    font-size: 18px;
                    font-family: regular-bold, sans-serif;
                    background: url(../../images/xexpand-closed.png) no-repeat left top;
                    padding-left: 27px;
                    min-height: 19px;
                    margin-block-end: 3px;

                    &.arrow_down {
                        background: url(../../images/xexpand-open.png) no-repeat left top;
                    }

                    &:empty {
                        display: none;
                    }
                }

                .question-by,.answer-by {
                    color: #999;
                    font-size: 10px;
                }

                .question-by {
                    margin-left: -23px;
                }

                .answer-content {
                    margin-block: 10px 3px;
                }

                .answer-content-wrap {
                    margin-left: 40px;
                    font-family: regular-book, sans-serif;
                    font-size: 17px;
                }
            }
        }
    }

    .gotMoreQstSections {
        background: #F5F5F5;
        width: 100%;
        margin-bottom: 50px;
        border: 1px solid #ddd;
        padding: 30px;
        text-align: center;
        border-radius: 4px;
        h2 {
            text-transform: uppercase;
            font-family: regular-bold, sans-serif;
            font-size: 35px;
            line-height: 1.1;
            margin: 0 0 25px;
        }
        p {
            font-family: regular-book, sans-serif;
            font-size: 20px;
            margin-bottom: 20px;
        }
        .btnWrap {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: -10px;

            a, button {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #666;
                font-family: regular-bold, sans-serif;
                color: #444;
                font-size: 20px;
                padding: 10px 20px;
                border-radius: 999px;
                text-transform: uppercase;
                margin: 10px 6px 0;
                &:hover {
                    background-color: var(--text-red-hover-color);
                    border-color: var(--text-red-hover-color);
                    color: #fff;
                }
            }
        }

        @include mobile {
            padding: 20px;
            h2 {
                font-size: 30px;
            }
            p {
                font-size: 18px;
            }
            .btnWarp {
                a {
                    font-size: 16px;
                    padding: 8px 12px;
                    margin: 5px 7px;
                }
            }
        }
    }
}

.paymentDropdown {
    margin-bottom: 15px;
    border-bottom: 1px solid #dddd;
    .accordion__panel {
        background: transparent;
        padding-bottom: 15px;
        &[data-hidden="true"] {
            display: none;
        }
    }
    .accordion__heading {
        border: none;
        padding: 0;
        .accordion__button {
            display: flex;
            width: 100%;
            align-items: center;           
            padding: 15px 0;
            border-top: 1px solid #dddd;
            margin: 10px 0 0;
            cursor: pointer;
            .toggle-nav-sec {
                margin-left: auto;
                svg {
                    width: 13px;
                }
            }
        }
    }
    .buyNowText {
        p {
            margin: 0;
            font-family: regular-book, sans-serif;
            font-size: 16px;
            color: #666;
            line-height: 1.2;
            &:first-child {
                text-transform: uppercase;
                font-family: regular-bold, sans-serif;
                font-size: 16px;
                color: #333;
            }
        }
    }
}

.payjustnow {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-family: regular-book, sans-serif;
    color: #333;
    font-size: 14px;
    font-weight: 400;
    gap: 10px;

    img {
        max-width: 58px;
        margin-inline-end: 10px;
    }

    strong {
        font-family: regular-bold, sans-serif;
        font-weight: 400;
    }

    button {
        color: #333;
    }

    #payjustnowinfo {
        text-decoration: underline;

        &:hover {
            color: var(--text-red-color);
        }
    }
}

.payflex {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-family: regular-book, sans-serif;
    color: #333;
    font-size: 14px;
    font-weight: 400;
    padding-block-end: 10px;
    margin-block-end: 10px;
    gap: 10px;

    img {
        max-width: 58px;
        margin-inline-end: 10px;
    }

    strong {
        font-family: regular-bold, sans-serif;
        font-weight: 400;
    }

    button {
        color: #333;
    }

    #payflexinfo {
        text-decoration: underline;

        &:hover {
            color: var(--text-red-color);
        }
    }
}

.LocalizationWrapper-en_GB .ProductPage {
    div#bread-btn-cntnr button#payjustnowinfo {
        line-height: 32px;
    }

    @include tablet {
        .ProductActions-Qty.Field {
            margin-inline-end: 10px;
        }

        div#bread-btn-cntnr button#payjustnowinfo {
            margin-left: 8px;
            padding: 0 7px;
        }

        .cart-wishlist-wrap .ProductActions-AddToCart.Button {
            padding: 2px 6px;
            font-size: 14px !important;
        }

        .ProductActions-Qty.Field,
        .ProductActions .GroupedProductsItem-Quantity {
            padding: 0 10px;
        }
    }
}

#payjustnow {
    &.modal {
        position: fixed;
        z-index: 999;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.4);
    }

    .modal-content {
        background-color: #fefefe;
        margin: 0 auto;
        border: none;
        padding: 5px;
        font-family: source sans pro, sans-serif;
        max-width: 700px;
        width: 80%;
        font-size: 13px;
        font-style: italic;
        color: #2b2e38;
        display: block;
        position: fixed;
        inset-inline: 0;
        inset-block-start: 50%;
        transform: translateY(-50%);
        max-height: 85%;
        overflow-y: auto;

        @include mobile {
            width: 100%;
            max-height: 100%;
            inset-block-start: 0;
            transform: translateY(0%);
        }

        h2.pjn-heading-large {
            font-size: 20px;
            margin: 0 0 20px;
            line-height: 1.4;
            text-transform: unset;
        }

        .pjn-emphasis {
            margin: 15px 0 10px;
            color: #ff5c75;
            font-size: 15px;
            font-weight: 600;
        }

        span.close {
            position: absolute;
            inset-block-start: 4px;
            right: 10px;
            line-height: normal;
            height: auto;
            color: #aaa;
            float: inline-end;
            font-size: 24px;
            font-weight: bold;
            cursor: pointer;
            z-index: 9;

            &:hover {
                color: #000;
            }
        }
    }

    .pjn-popup-header {
        margin-block-end: 20px;
        padding: 40px 40px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;

        @include mobile {
            padding: 40px 10px 0;
            text-align: center;
        }

        .pjn-logo {
            display: inline-block;
            vertical-align: top;
            width: 180px;
            max-width: 100%;
            height: 180px;
            background: #14204a;
            text-align: center;
            align-items: center;
            border-radius: 50%;
            clear: both;
            margin-inline-end: 20px;
            position: relative;

            @include mobile {
                margin: 0 0 20px;
            }

            img {
                inset-inline: 0;
                margin: 0 auto;
                inset-block-start: 45%;
                transform: translateY(-50%);
                width: 150px;
            }
        }
    }

    .pjn-header-right {
        width: 59%;
        display: inline-block;
        padding-inline-end: 40px;

        @include mobile {
            padding-inline-end: 0;
            width: 80%;
        }
    }

    .pjn-popup-body {
        padding: 20px 40px;

        @include mobile {
            padding: 20px 10px;
        }
    }

    .pjn-popup-body-grid {
        @include mobile {
            text-align: center;
        }

        & > div {
            display: inline-block;
            width: 20%;
            vertical-align: top;
            margin-inline-end: 35px;

            @include mobile {
                width: 43%;
                margin-inline-end: 15px;
            }

            &:last-child {
                margin-inline-end: 0;
            }

            img {
                min-height: 50px;
                width: auto;
            }

            p {
                font-size: 11px;
            }
        }
    }

    .pjn-popup-footer {
        padding: 20px 40px;
        position: relative;
        overflow: hidden;

        @include mobile {
            padding: 20px 10px 40px;
        }
    }

    .pjn-footer-box {
        border: 1px solid #1f244720;
        border-radius: 6px;
        padding: 30px 20px;
        border-left: 3px #14204a solid;
    }

    hr.pjn-hr {
        border: 0;
        border-top: 1px solid #ccc;
        margin-block: 20px;
    }

    .bottom-text {
        text-align: start;
        font-size: 11px;
        color: inherit;

        div {
            display: inline-block;
            margin-inline-end: 5px;

            img {
                margin-inline-end: 2px;
            }
        }
    }

    h4.pjn-heading-small {
        margin: 20px 0;
    }

    .bottom-text div > img,
    .bottom-text div > p {
        display: inline-block;
        vertical-align: middle;
        margin-block-end: 0;
        width: auto;
    }

    .button-learn-more {
        text-align: end;

        a {
            background-image: linear-gradient(
                to right,
                #ff516c 0%,
                #ff7567 51%,
                #ff516c 100%
            );
            margin: 1rem 0 10px;
            padding: 10px;
            text-align: center;
            text-transform: none;
            background-size: 200% auto;
            width: 200px;
            transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
            cursor: pointer;
            display: inline-block;
            border-radius: 10px;
            line-height: 1.2rem;
            font-size: 16px;
            color: #fff;
            text-decoration: none;

            &:hover {
                background-position: right center;
            }
        }
    }
}

div .ProductPrice {
    min-height: unset;
}

.GiftCardDetails {
    font-family: "Regular-Book", sans-serif;

    .GiftPriceUI {
        margin-block-end: 15px;

        .GiftPriceItems {
            display: flex;
            flex-wrap: wrap;

            .GiftPriceItem {
                margin: 0 10px 5px 0;
                padding: 6px 8px;
                border: 1px solid #dadada;
                background-color: #f0f0f0;
                font-size: 14px;
                transition: background-color 0.3s ease, border 0.3s ease;
                border-radius: 2px;
                cursor: pointer;
            }

            .GiftPriceItemSelected {
                margin: 0 10px 5px 0;
                padding: 6px 8px;
                font-size: 14px;
                transition: background-color 0.3s ease, border 0.3s ease;
                border-radius: 2px;
                border: 1px solid var(--text-red-color);
                background-color: var(--text-red-color);
                color: #fff;
            }
        }
    }

    .GiftAmountInputFiled {
        input {
            min-width: unset;
            width: 100%;
            height: 32px;
            padding: 0 9px;
            border: 2px solid rgba(0, 0, 0, 0.2);
        }

        .Field {
            margin-block-start: 0;
        }

        .giftInputApply {
            display: flex;

            .SetAmountButton {
                line-height: 28px;
            }
        }
    }

    h1.GiftImageItems {
        margin-block-start: 20px;
    }

    textarea {
        max-width: 100%;
        width: 100%;
    }

    h1 {
        margin: 0 0 5px;
        color: #232323;
        font-size: 16px;
        line-height: 19px;
        font-family: "regular-bold", arial, sans-serif;
        text-transform: uppercase;
    }

    div.GiftImageItems {
        font-size: 0;

        @include mobile {
            white-space: nowrap;
        }

        > div {
            width: 98px;
            display: inline-block;
            border: 2px solid transparent;

            @include desktop {
                max-width: 20%;
            }
        }

        > div.GiftImageItemSelected {
            border-color: var(--text-red-color);
        }

        img {
            display: block;
        }
    }

    h1.EnterGiftDetails {
        margin-block-start: 18px;
    }

    .GiftCardForm {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px 20px;

        .Field {
            display: flex;
            flex-direction: column;
            flex-basis: 50%;
            max-width: 50%;
            padding: 0 5px;

            &:nth-child(3) {
                flex-basis: 100%;
                max-width: 100%;
            }

            label.Field-Label {
                border: none;
                font-weight: normal;
                margin-block-end: 3px;
                font-size: 16px;
            }

            input {
                padding: 7px;
                border: 2px solid rgba(0, 0, 0, 0.2);
            }

            textarea {
                padding: 7px;
                border: 2px solid rgba(0, 0, 0, 0.2);
                height: 55px;
            }
        }

        .Field.Field_type_textarea {
            flex-basis: 100%;
            max-width: 100%;
        }
    }

    .GiftImageItems-wrap {
        overflow-x: auto;
        width: 100%;
        max-width: calc(100vw - 30px);
    }
}

// display: flex;
// width: 100%;
// margin: 0 0 10px;
// border-top: 1px #ececec solid;
// padding-top: 9px;
// align-items: center;
// justify-content: center;
// font-family: regular-book,
// sans-serif;
// color: #333;
// font-size: 14px;

.zipWidget-Buttons {
    font-family: regular-book, sans-serif;
    color: #333;
    width: 100%;
    margin: 0 0 10px 0;
    padding: 0;

    .zip-container {
        display: flex;
        cursor: pointer;

        img.zip-logo {
            width: auto;
            margin: 0;
        }

        .zip-payment-amount {
            margin-inline: 5px;
        }

        button#zip-info-link {
            border: 2px solid rgba(0, 0, 0, 0.2);
            padding: 0 8px;
        }
    }

    .zip-widget__wrapper {
        width: 100%;
        padding: 0;

        .zip-widget__tagline {
            width: 100%;
        }

        .divider::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 0;
            border-top: 1px solid #eee;
            top: -10px;
            left: 0;
        }
    }
}

.VideosPopup {
    position: fixed;
    inset: 0;
    background-color: rgba(35, 35, 35, 0.7);
    z-index: 999;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &Wrapp {
        overflow: hidden;
        position: relative;
        width: 82%;
        max-width: 1320px;
        margin: 0 auto;
        max-height: 100%;

        @include mobile {
            width: 96%;
        }

        &::after {
            padding-block-start: 56.25%;
            display: block;
            content: "";
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .close {
            background: url("../../images/fancybox_sprite.png");
            z-index: 999;
            right: 0;
            inset-block-start: 0;
            position: absolute;
            width: 36px;
            height: 36px;
            font-size: 0;
        }
    }
}
