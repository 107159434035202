/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable declaration-no-important */

.ProductReviews {
    padding-block-start: 20px;

    .ProductReview {
        &List {
            display: block;
            padding: 0 !important;
            list-style: none !important;
        }

        &Item {
            padding: 0 0 20px;
            margin-block-end: 20px !important;
            border: none;
            border-bottom: 1px solid var(--product-review-item-border);

            &:last-child {
                margin-block-end: 0 !important;
                padding-block-end: 0;
                border: none;
            }
        }
    }

    &-Wrapper {
        padding-block-end: 0;
    }

    button.ProductReviews-Button.Button {
        height: max-content;
    }
}

.ProductReviewRating-Counter {
    display: none;
}
