/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductReviews {
    @include desktop {
        z-index: 1;
    }

    &-Title {
        margin: 0 0 .5rem;
        font-size: 2rem;

        @include mobile {
            display: none;
        }
    }

    &-Wrapper {
        padding: 0;

        @include desktop {
            padding: 3rem 1rem;
        }
    }

    &-SummaryRating {
        font-size: 30px;
    }

    &-ExpandableContentHeading,
    &-ExpandableContentButton {
        @include desktop {
            display: none;
        }
    }

    &-ExpandableContentContent {
        @include desktop {
            margin-block-start: 0;
        }

        &_isContentExpanded {
            padding-block-start: 0;
        }
    }

    & &-Button {
        position: absolute;
        right: 0;
        inset-block: 0;
        height: max-content;
        margin: auto;

        @include mobile {
            position: relative;
            height: auto;
            width: 100%;
            margin-block-start: 1rem;
        }
    }

    &-SummaryDetails {
        display: inline-block;
        margin-left: 1rem;
        font-size: 20px;

        span {
            font-size: 16px;
            font-weight: normal;

            &::before {
                content: ' / ';
            }
        }
    }
}

.product-info {
    .ProductQA-QA {
        .ProductQAList {
            .singleQA {
                border-bottom: 1px solid #e2e2e2;
                padding-block-end: 10px;
                margin-block: 10px;

                &:last-child {
                    border: none;
                }

                .question-content {
                    font-size: 17px;
                    font-family: regular-bold, sans-serif;
                    background: url(../../images/xexpand-closed.png) no-repeat left top;
                    padding-left: 27px;
                    min-height: 19px;
                    margin-block-end: 3px;

                    &.arrow_down {
                        background: url(../../images/xexpand-open.png) no-repeat left top;
                    }

                    &:empty {
                        display: none;
                    }
                }

                .question-by,.answer-by {
                    color: #999;
                    font-size: 10px;
                }

                .question-by {
                    margin-left: -23px;
                }

                .answer-content {
                    margin-block: 10px 3px;
                }

                .answer-content-wrap {
                    margin-left: 40px;
                }
            }
        }
    }
}
