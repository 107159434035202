/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable declaration-no-important */

.GroupedProductsItem {
    .out_of_stock {
        font-family: regular-bold, sans-serif;
        font-size: 16px;
        text-transform: uppercase;
        text-align: end;
        padding-inline-end: 10px;
        color: var(--text-gray-color);
    }

    .notify_me {
        display: flex;

        .Field {
            margin: 0;

            input[type="email"] {
                border-right: 0;
                height: 44px;
                border-top-left-radius: 999px;
                border-bottom-left-radius: 999px;
                padding: 5px 15px;
                width: 360px;
            }
        }

        button.Button {
            font-size: 18px !important;
            margin-left: 0;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            height: 44px;
            padding: 0 15px 0 10px;
        }
    }

    .ProductNotifyEmail {
        min-width: 350px;

        .Elabel {
            font-family: regular-bold, sans-serif;
            font-size: 17px;
            color: #333;
            margin-block-end: 7px;
            padding-left: 5px;
        }

        .ProductNotifyEmail-Message {
            margin-block-start: 5px;
            text-align: start;
            font-family: regular-book, sans-serif;
            color: #333;
            padding-left: 5px;
        }
    }
}

.ProductActions-GroupedItems {
    & ~ .ProductActions-AddToCartWrapper {
        .cart-wishlist-wrap.no_stock {
            .ProductNotifyEmail {
                display: none !important;
            }
        }
    }
}
