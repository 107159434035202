/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductReviewForm {
    &-Wrapper {
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 2fr;
            grid-gap: 24px;
        }
    }

    &-ProductName {
        font-weight: 400;
        font-size: 24px;

        @include mobile {
            font-size: 25px;
        }
    }

    &-Content {
        @include mobile {
            margin-block-start: 28px;
        }
    }

    &-Rating {
        [dir="rtl"] & {
            display: flex;
            flex-direction: row-reverse;
        }

        margin-block-start: 12px;
        margin-block-end: 16px;
        margin-inline: 0;
        text-align: start;
        direction: rtl;
        border: 0;
        /* stylelint-disable-next-line unknownProperties, property-no-unknown */
        white-space-collapse: discard; // alternative of `font-size: 0`

        @include mobile {
            margin-block-start: 14px;
            margin-block-end: 18px;
            margin-inline: 0;
        }

        &:first-child {
            @include mobile {
                margin-block-start: 0;
            }
        }
    }

    &-Legend {
        border-block-end: 0;
        padding-block-end: 4px;
        font-weight: 700;
        font-size: 13px;

        @include mobile {
            padding-block-end: 4px;
            font-size: 15px;
        }
    }

    & &-Button {
        margin-block-start: 12px;

        @include mobile {
            margin-block-start: 28px;
            width: 100%;
        }
    }

    input,
    textarea {
        width: 100%;
    }
}

.Popup {
    &.ReviewPopup {
        background-color: rgba(0, 0, 0, 0.158);

        .ReviewStar {
            cursor: pointer;
        }

        .Popup-Heading {
            font-family: 'Regular-Bold', sans-serif;
            text-transform: uppercase;
            padding-block-end: 10px;
        }

        .Field label {
            font-weight: normal;
        }
    }
}
