/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable declaration-no-important */
/* stylelint-disable property-no-unknown */

.product-info {
    margin-block-end: 50px;
    
    .accordion__item {
        margin-bottom: 8px;
    }

    .accordion__heading {
        padding: 0;
        border: none;
        cursor: pointer;

        .accordion__button {
            background: #F5F5F5;
            border: 1px solid #ddd;
            padding: 15px 0 15px 20px;

            &[aria-expanded="true"] {
                background: #323232;
                border-color: #323232;
                color: #fff;
            }
        }
    }

    .toggle-nav {
        border-left: none;
        font-size: 16px;

        svg {
            transition: transform 0.3s linear;
        }

        &.expanded {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    .accordion__panel {
        background-color: transparent;
        border: 1px solid #ddd;
        border-top: none;
        font-size: 17px;
        font-weight: 400;
        color: var(--text-light-black-color);
        line-height: 1;
        font-family: regular-book, sans-serif;
        padding: 20px;
        word-wrap: break-word;
        word-break: break-word;

        &[data-hidden="true"] {
            display: none;
        }

        a,
        a:visited {
            color: var(--text-dark-gray-color);
            text-decoration: underline;
        }

        a:hover {
            color: var(--text-red-color);
        }

        .lear_more a.btn.btn-primary {
            color: var(--btn-text-color);
            text-decoration: none;
        }

        ul,
        ol {
            padding-left: 15px;

            li {
                margin: 5px 0;
                line-height: 20px;

                &::before {
                    display: none;
                }
            }
        }

        ul {
            list-style: disc;
        }

        .vid-container {
            max-width: 512px;
            width: 100%;
            margin-inline: auto;
        }

        .iframe_wrap {
            position: relative;
            height: 0;
            overflow: hidden;
            padding: logical 0 0 56.25%;
            margin-block-start: 10px;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 0;
            }
        }

        p {
            font-size: inherit;
            line-height: normal;
        }

        .warningText {
            font-size: 16px;
            background-image: url('../../images/warning.png');
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 24px;
            padding: 12px 0 12px 32px;
            margin-top: 15px;
        }

        .install .product_downloads {
            margin-block-end: 25px;

            .pd_title {
                margin-block-end: 5px;

                a {
                    font-family: regular-bold, sans-serif;
                    font-weight: 400;
                    text-decoration: none;
                }
            }

            .pd_wrap {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -15px;

                .pd_img,
                .pd_pdf {
                    width: 40%;
                    padding: 0 15px;

                    .Button {
                        margin-block-start: 15px;
                        text-decoration: none;
                    }

                    @include mobile {
                        width: 100%;
                    }

                    .Image_ratio_square,
                    .Image {
                        padding: 0;
                        height: auto;

                        .Image-Image {
                            position: static;
                        }
                    }
                }

                .pd_attributes {
                    font-size: 16px;
                    margin-block-start: 15px;
                    color: var(--text-dark-gray-color);

                    ul {
                        li {
                            line-height: 1.2;

                            a {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }

        .Button {
            font-size: 14px;
            transition: all 0.24s linear 0s;

            &:hover {
                background-color: var(--text-red-hover-color);
                text-decoration: none;
            }
        }

        .ProductQA {
            .ContentWrapper {
                padding: 0;

                h3 {
                    font-size: 18px;
                    line-height: 40px;
                    margin: 5px 0;
                }

                .Button {
                    float: inline-end;
                    z-index: 1;
                }

                .qa-des {
                    font-style: italic;
                }
            }
        }
    }

    .Button {
        color: var(--button-color) !important;
        border-radius: 999px !important;

        &:hover {
            border-radius: 999px !important;
        }
    }
}

.ProductQA,
.ProductReviews,
.EmailFriend {
    &-Popup {
        .Form,
        .EmailFriendForm-Content {
            border: 1px solid #e2e2e2;
            background-color: var(--tab-bg-color);
            padding: 25px;

            .Field {
                margin: 0 0 20px;
            }

            label,
            legend {
                font-family: regular-book, sans-serif;
                font-size: 17px;
                font-weight: 400;
                color: var(--text-light-black-color);
                padding-block-end: 8px;
            }

            input[type="text"],
            input[type="email"],
            textarea {
                border: 2px solid rgba(0, 0, 0, 0.2);
                height: 32px;
                width: 100%;
                padding: 0 9px;
                border-radius: 0;
            }

            textarea {
                height: auto;
                padding: 10px;
            }

            .Button {
                font-size: 14px;
                transition: all 0.24s linear 0s;

                &:hover {
                    background-color: var(--text-red-hover-color);
                    text-decoration: none;
                }
            }
        }
    }
}

.ProductReviews {
    &-Popup {
        .Form {
            background-color: var(--transform-bg);
            padding: 20px;

            legend {
                float: inline-start;
                width: auto;
                min-width: 65px;
                padding-block-start: 2px;
            }

            .Button {
                font-size: 14px;
                transition: all 0.24s linear 0s;

                &:hover {
                    background-color: var(--text-red-hover-color);
                    text-decoration: none;
                }
            }

            .ReviewStar {
                font-size: 28px;
            }
        }
    }
}

.EmailFriend {
    &-Popup {
        .Form {
            background-color: var(--transform-bg);
            padding: 0;
            border: none;

            .EmailFriendForm-Content {
                margin-block-end: 30px;

                h1 {
                    border-bottom: 1px dotted var(--border-color);
                    padding: 0 0 30px;
                    font-size: 24px;
                    color: var(--text-dark-gray-color);
                    margin-block-end: 20px;
                }

                & > div:nth-child(2) .remove-wrap {
                    display: none;
                }

                .remove-wrap {
                    text-align: center;
                    border-top: 1px solid var(--border-color-light);
                    padding-block-start: 15px;
                    margin-block-start: 40px;
                }
            }

            .EmailFriend-Buttons {
                padding-block-end: 20px;

                .btn {
                    width: 100%;
                    text-align: center;
                }

                .Button {
                    background-color: var(--text-red-color);
                    width: 100%;
                    margin-block-start: 20px;
                }
            }
        }
    }
}

div .ReviewStar:checked,
div .ReviewStar:hover,
div .ReviewStar[checked],
div .ReviewStar:checked ~ input,
div .ReviewStar:hover ~ input,
div .ReviewStar[checked] ~ input {
    --star-outline-color: var(--text-red-dark-color);

    color: var(--text-red-dark-color);
}
